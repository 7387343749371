import React, { useState, useEffect, useRef } from 'react';
import style from '../../HomePage.module.css';
import { Theme, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import base64 from 'base-64';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { REACT_APP_API_ENDPOINT } from '../../conf';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function BurnedPages({ login, password }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const goBack = () => {
        navigate('/')
        window.localStorage.setItem('login', '')
        window.localStorage.setItem('password', '')
    };

    const [burns, setBurns] = useState([]);
    const [total, setTotal] = useState({})
    const anchorRef = useRef(null);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "multipart/form-data");

    function createBurns(
        dataroomUrl,
        etherscanUrl,
        timestamp,
        hash,
        from,
        value
    ) {
        return { timestamp, hash, from, value };
    };

    function getBurnsData() {
        var graphqlburns = JSON.stringify({
            query: "{\r\n  burns(\r\n    sortBy: TIMESTAMP, sortOrder: DESC,\r\n    offset: 0, \r\n    limit: 1000\r\n  ) {\r\n    date,\r\n    hash,\r\n    from { address, name, etherscanUrl,  dataroomUrl, number }\r\n    value { eth }\r\n  }\r\n}\r\n",
            variables: {}
        })

        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlburns, { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => setBurns(res.data.data.burns))
            .catch(error => console.log('error', error));

        if (burns.length) {
            burns?.map((item, index) => createBurns(item.dataroomUrl,item.etherscanUrl,item.number, item.date, item.hash, item.from, item.value));
        }
    };
    function totalBurned() {
        var graphqlTotalBurned = JSON.stringify({
            query: "{ totalBurned { eth } }",
            variables: {}
          }, myHeaders)
          axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlTotalBurned,
          { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
          .then(({data}) => setTotal(data.data.totalBurned))
          .catch(err => console.log(err))
    }

    useEffect(() => {
        getBurnsData();
        totalBurned();

    }, [])

    return (
        <div className={style.table}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ padding: '3px' }} align="center">Date</StyledTableCell>
                            <StyledTableCell sx={{ padding: '3px' }} align="center">Hash</StyledTableCell>
                            <StyledTableCell sx={{ padding: '3px' }} align="center">Number</StyledTableCell>
                            <StyledTableCell sx={{ padding: '3px' }} align="center">From</StyledTableCell>
                            <StyledTableCell sx={{ padding: '3px' }} align="center">Name</StyledTableCell>
                            <StyledTableCell sx={{ padding: '3px' }} align="center">Staked Tokens</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <StyledTableRow>
                    <StyledTableCell sx={{ padding: '3px' }} colSpan={6}>
                        <div className={style.totalBurned}> TOTAL BURNED: {total.eth}</div>
                    </StyledTableCell>
                    </StyledTableRow>
                    </TableBody>
                    <TableBody>
                        {burns && burns?.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell sx={{ padding: '3px' }} align="center">{row.date}</StyledTableCell>
                                <StyledTableCell sx={{ padding: '3px' }} align="center">{row.hash}</StyledTableCell>
                                <StyledTableCell sx={{ padding: '3px', cursor: 'pointer' }} align="center"><a href={row.from.dataroomUrl} target="_blank">{row.from.number}</a></StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="item" sx={{ padding: '3px', cursor: 'pointer', color: '#ffa726' }}><a href={row.from.etherscanUrl} target="_blank">{row.from.address}</a></StyledTableCell>
                                <StyledTableCell sx={{ padding: '3px' }} align="center">{row.from.name}</StyledTableCell>
                                <StyledTableCell sx={{ padding: '3px' }}>{row.value.eth}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )

}



