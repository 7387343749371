import React, { useState, useEffect, useRef } from 'react';
import style from './HomePage.module.css';
import { styled } from '@mui/material/styles';
import base64 from 'base-64';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import { RadioGroup, Radio } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import axios from 'axios';
import { Theme, useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import "react-datepicker/dist/react-datepicker.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BurnedPages from './Pages/BurnedPages/BurnedPages';
import CalendarPages from './Pages/CalendarPages/CalendarPages';
import ClaimsTopThreeMonthsPages from './Pages/ClaimsTopThreeMonthsPages/ClaimsTopThreeMonthsPages';
import ClaimsTopWeekPages from './Pages/ClaimsTopWeekPages/ClaimsTopWeekPages'
import TokenHoldersPages from './Pages/TokenHoldersPages/TokenHoldersPages';
import ModalRejected from './Components/Modals/ModalRejected/ModalRejected';
import ModalCancelled from './Components/Modals/ModalCancelled/ModalCancelled';
import ModalConnected from './Components/Modals/ModalConnected/ModalConnected';
import ModalAddWallet from './Components/Modals/ModalAddWallet/ModalAddWallet';
import { REACT_APP_API_ENDPOINT } from './conf';
import CommentPlace from './Components/Comment/Comment';
import useReadOnly from './hook/useReadOnly';

const stylesModalAddWallets = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Nawtable({ login, password }) {
    const flagReadOnly = useReadOnly(base64.encode(login + ":" + password));
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate();
    const theme = useTheme();
    const goBack = () => {
        navigate('/')
        window.localStorage.setItem('login', '')
        window.localStorage.setItem('password', '')
    };
    /*const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);
    const [checked4, setChecked4] = useState(false);
    const [checked5, setChecked5] = useState(false);
    const [checked6, setChecked6] = useState(false);
    const [checked7, setChecked7] = useState(false);
    const [checked8, setChecked8] = useState(false);
    const [radioError, setRadioError] = useState(false);*/

    const [data, setData] = useState([]);
    const [index, setIndex] = useState(0);
    const [uploaders, setUploaders] = useState([]);

    const [fileType, setFileType] = useState('');

    const [openConnected, setOpenConnected] = useState(false);
    const [openRejected, setOpenRejected] = useState(false);
    const [openCancelled, setOpenCancelled] = useState(false);
    const [openAddWallet, setOpenAddWallet] = useState(false);
    const [file, setFile] = useState('');
    const [hasCompanyReserveWallet, setHasCompanyReserveWallet] = useState(false);
    const [hasMiningReserveWallet, setHasMiningReserveWallet] = useState(false);
    
    const handleOpenAddWallet = () => setOpenAddWallet(true);
    const handleCloseAddWallet = () => setOpenAddWallet(false);
    const handleOpenConnected = () => setOpenConnected(true);
    const handleCloseConnected = () => setOpenConnected(false);
    const handleOpenRejected = () => setOpenRejected(true);
    const handleCloseRejected = () => setOpenRejected(false);
    const handleOpenCancelled = () => setOpenCancelled(true);
    const handleCloseCancelled = () => setOpenCancelled(false);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "multipart/form-data");

    function createData(
        number,
        address,
        name,
        cwebBalance,
        cwebAllocation,
        totalAmount,
        unclaimedAmount,
        claimedAmount
    ) {
        return { number, address, name, cwebBalance, cwebAllocation, totalAmount, unclaimedAmount, claimedAmount };
    };
    function getMainData() {
        window.location.reload();
        // перезагрузить страницу
        /*var graphql = JSON.stringify({
            query: "{\r\n  wallets(\r\n    sortBy:  CWEB_ALLOCATION,\r\n    sortOrder: DESC,\r\n    offset: 0,\r\n    limit: 100\r\n  ) {\r\n    number,\r\n    address,\r\n    name,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n  }\r\n}",
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphql,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }*/
    };
    function refresh() {
        let graphqlRefresh = JSON.stringify({
            query: "query {refresh(policy: TRANSFERS_WITH_WALLETS)}",
            variables: {}
        }, myHeaders)
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlRefresh,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(() => getMainData())
            .catch(err => console.log(err))
    }
    function uploader() {
        let data = JSON.stringify(uploaders);
        const form = new FormData();
        form.append('operations', '{ "query": "mutation ($file: Upload!) { importCsvWallets(file: $file) }", "variables": { "file": null } }');
        form.append('map', '{ "0": ["variables.file"] }');
        form.append('0', uploaders[0], data);

        axios.post(`${REACT_APP_API_ENDPOINT}`, form,
            { headers: {'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(result => console.log(uploaders))
            .catch(err => console.log(err, 'err'))
        /* let graphqlUploader = JSON.stringify({
            query: `mutation fl ($file: Upload!) { importCsvWallets(file: ${data}) }`,
            variables: `{file:${uploaders}}`
        }, myHeaders)
        console.log('start')
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlUploader,
            { headers: { 'Content-Disposition': 'form-data; name="operations"', 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(result => console.log(uploaders))
            .catch(err => console.log(err, 'err'))*/
    }

    useEffect(() => {
        axios.post(REACT_APP_API_ENDPOINT, JSON.stringify({ query: `{hasCompanyReserveWallet}` }), {
            headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) }
        })
            .then(res => {
                console.log(res);
                console.log(res.data.data.hasCompanyReserveWallet);
                setHasCompanyReserveWallet(res.data.data.hasCompanyReserveWallet);
            })
            .catch(err => console.log(err))
        axios.post(REACT_APP_API_ENDPOINT, JSON.stringify({ query: `{hasMiningReserveWallet}` }), {
                headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) }
            })
                .then(res => {
                    console.log(res);
                    console.log(res.data.data.hasMiningReserveWallet);
                    setHasMiningReserveWallet(res.data.data.hasMiningReserveWallet);
                })
                .catch(err => console.log(err))
    }, [])

    function dumpWallet() {
        setFileType(".txt");
        setOpenRejected(true);
        let data = JSON.stringify(uploaders);
        let graphqlUploader = JSON.stringify({
            query: `{dumpBeneficiaries}`,
            variables: {}
        }, myHeaders)
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlUploader, { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(result => {
                setOpenRejected(false);
                console.log(result.data.data.dumpBeneficiaries)
                let type = 'data:application/octet-stream;base64, ';
                let text = result.data.data.dumpBeneficiaries;
                let base = btoa(text);
                let res = type + base;
                setFile(res);
                setOpenConnected(true);
            })
            .catch(err => {
                setOpenRejected(false);
                setOpenCancelled(true);
                console.log(err);
            })
    }

    function dumpCsvWallet() {
        setFileType(".csv");
        setOpenRejected(true);
        let data = JSON.stringify(uploaders);
        let graphqlUploader = JSON.stringify({
            query: `{exportCsv}`,
            variables: {}
        }, myHeaders)
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlUploader, { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(result => {
                setOpenRejected(false);
                console.log(result.data.data.exportCsv)
                let type = 'data:application/octet-stream;base64, ';
                let text = result.data.data.exportCsv;
                let base = btoa(unescape(encodeURIComponent(text)));
                let res = type + base;
                setFile(res);
                setOpenConnected(true);
            })
            .catch(err => {
                setOpenRejected(false);
                setOpenCancelled(true);
                console.log(err);
            })
    }
    
    function dumpUnknownWallet(){
        setFileType(".txt");
        setOpenRejected(true);
        let data = JSON.stringify(uploaders);
        let graphqlUploader = JSON.stringify({
            query: `{dumpBeneficiaries(inlcudeKnownWallets: false)}`,
            variables: {}
        }, myHeaders)
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlUploader, { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(result => {
                setOpenRejected(false);
                console.log(result.data.data.dumpBeneficiaries)
                let type = 'data:application/octet-stream;base64, ';
                let text = result.data.data.dumpBeneficiaries;
                let base = btoa(text);
                let res = type + base;
                setFile(res);
                setOpenConnected(true);
            })
            .catch(err => {
                setOpenRejected(false);
                setOpenCancelled(true);
                console.log(err);
            })
    }
    return (
        <div className={style.all_content}>
            <div className={style.all_button}>
                <div className={style.two_btns}>
                    { !flagReadOnly &&
                        <>
                            <div onClick={() => { setIndex(0) }}>
                                <Button sx={{'padding-left': '3px', 'padding-right': '3px'}} onClick={() => setOpenAddWallet(true)} variant="outlined">Add wallet</Button>
                            </div>
                            <div>
                                <Button sx={{'padding-left': '3px', 'padding-right': '3px'}} variant="outlined" onClick={() => { setIndex(2) }}>Import CSV</Button>
                            </div>
                        </>
                    }
                    <div>
                        <Button sx={{'padding-left': '3px', 'padding-right': '3px'}} variant="outlined" onClick={dumpCsvWallet}>Export CSV</Button>
                    </div>
                    <div>
                        <Button sx={{'padding-left': '3px', 'padding-right': '3px'}} variant="outlined" onClick={dumpUnknownWallet}>DUMP UNKNOWN WALLETS</Button>
                    </div>
                    {/*<div>
                        <Button sx={{'padding-left': '3px', 'padding-right': '3px'}} variant="outlined" onClick={dumpWallet}>Dump ALL wallets</Button>
                    </div>*/}
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={refresh}
                    >
                        Refresh
                    </Button>
                </div>
                <div className={style.all_group_button}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <Tabs value={value} onChange={handleChange} centered>
                                <Tab label="Token holders" onClick={() => { setIndex(0) }} />
                                <Tab label="Burned" onClick={() => { setIndex(1) }} />
                                <Tab label="This week claims" onClick={() => { setIndex(4) }} />
                                <Tab label="3 months claims" onClick={() => { setIndex(3) }} />
                                <Tab icon={<CalendarMonthIcon />} fontSize="large" sx={{ ml: 2, color: "#8bc34a" }} onClick={() => { setIndex(5) }} />
                            </Tabs>
                        </Box>
                    <Button onClick={goBack} sx={{ ml: 5, color: 'success' }} variant="outlined" color="success" >
                        <ExitToAppIcon sx={{ mr: 1 }} />
                        Exit
                    </Button>
                </div>
            </div>
            <div className={style.table} hidden={index !== 0} >
                <TokenHoldersPages login={login} password={password} flagReadOnly={flagReadOnly} />
            </div>
            <div className={style.table} hidden={index !== 1} >
                <BurnedPages login={login} password={password}/>
            </div>
            <div hidden={index !== 2}>
                <div className={style.uploader_style}>
                    <Dropzone onDrop={acceptedFiles => setUploaders(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} type='file' />
                                    <p className={style.uploader_text}>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <button className={style.btn_uploader} onClick={() => uploader()}>Send</button>
                </div>
            </div>
            <div className={style.table} hidden={index !== 3} >
                <ClaimsTopThreeMonthsPages login={login} password={password}/>
            </div>
            <div className={style.table} hidden={index !== 4} >
                <ClaimsTopWeekPages login={login} password={password}/>
            </div>
            <div className={style.table} hidden={index !== 5} >
                <CalendarPages login={login} password={password}/>
            </div>
            <CommentPlace auth={base64.encode(login + ":" + password)} flagReadOnly={flagReadOnly} />
            <ModalAddWallet open={openAddWallet} handleClose={handleCloseAddWallet} auth={base64.encode(login + ":" + password)} />
            <ModalRejected open={openRejected} handleClose={handleCloseRejected} />
            <ModalCancelled open={openCancelled} handleClose={handleCloseCancelled} />
            <ModalConnected open={openConnected} handleClose={handleCloseConnected} file={file} fileType={fileType}/>
        </div>
    );
}
