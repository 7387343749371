import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const FilterInput = (props) => {

    
    const [search, setSearch] = useState('');

    return (
        <div>
            <form className={props.classname1} onSubmit={props.handleSubmit}>
                <input
                    className={props.classname2}
                    placeholder={props.placeholder}
                    value={search}
                    onChange={e => {
                        setSearch(e.target.value)
                    }}
                />
                <button className={props.classname3} onClick={() => { props.searchClick(search) }} type="submit">
                    {props.personalIcon=="Manage" && <ManageSearchIcon color="#e8eaf6" />}
                    {props.personalIcon=="Person" && <PersonSearchIcon color="#e8eaf6" />}
                </button>
            </form>
        </div>
    )
}
export default FilterInput;