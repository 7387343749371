import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import base64 from 'base-64';
import styles from './ModalAddWallet.module.css';
import { REACT_APP_API_ENDPOINT } from '../../../conf';

const stylesModalAddWallets = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalAddWallet = (props) => {
  const [newAddres, setNewAddres] = useState('');
  const [newName, setNewName] = useState('');
  const [newUrl, setNewUrl] = useState('');
  const [adressError, setAdressError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');
  

  function getMainData() {
    window.location.reload();
    // перезагрузить страницу
    /*var graphql = JSON.stringify({
        query: "{\r\n  wallets(\r\n    sortBy:  CWEB_ALLOCATION,\r\n    sortOrder: DESC,\r\n    offset: 0,\r\n    limit: 100\r\n  ) {\r\n    number,\r\n    address,\r\n    name,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n  }\r\n}",
        variables: {}
    })
    axios.post(`${REACT_APP_API_ENDPOINT}`, graphql,
        { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
        .then(res => { setData(res.data.data.wallets) })
        .catch(err => console.log(err))
    if (data.length) {
        data?.map((item, index) => createData(item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
    }*/
  };

  function addNewData() {
    if (newAddres.trim().length && newName.trim().length) {
      let query = `mutation {
        addWallet(address: "${newAddres}", name: "${newName}", dataroomUrl: "${newUrl}") { address }}`
      if (newUrl === '') {
        query = `mutation {
          addWallet(address: "${newAddres}", name: "${newName}") { address }}`
      }
      
      axios.post(`${REACT_APP_API_ENDPOINT}`, {
        query
      }, { headers: { 'Authorization': 'Basic ' + props.auth }})
        .then(res => {
          console.log(res)
          if ('errors' in res.data)
          {
            console.log('error', res.data);
            // setUrlError(true);
            // setNewAddres('');
            // setAdressError(true);
            setErrorMessages(res.data.errors[0].message);
          }
          else
          {
              getMainData();
              //props.handleClose();
              setNewUrl('');
              setNewAddres('');
              setNewName('');
              setAdressError(false);
              setUrlError(false);
          }
        })
        .catch(err => console.log(err))
    }
    else setAdressError(true);
  };
  return (
    <Modal
      align="center"
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={stylesModalAddWallets}>
        <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
          Add Wallet
        </Typography>
        <TextField
          error = {adressError}
          autoComplete='off'
          sx={{ m: 1, width: '45ch' }}
          id="outlined-basic"
          label="address"
          variant="outlined"
          value={newAddres}
          onChange={e => setNewAddres(e.target.value)} />
        <TextField
          autoComplete='off'
          sx={{ m: 1, width: '45ch' }}
          id="outlined-basic"
          label="name"
          value={newName}
          onChange={e => setNewName(e.target.value)} />
        <TextField
          error = {urlError}
          autoComplete='off'
          sx={{ m: 1, width: '45ch' }}
          id="outlined-basic"
          label="Data Room URL"
          value={newUrl}
          onChange={e => setNewUrl(e.target.value)} />
        {errorMessages && <div className={styles.errorText}>
          {errorMessages}
        </div>}
        <div className={styles.btn_add_clear}>
          <Button onClick={()=>addNewData()} sx={{ mr: 15 }} variant="outlined">Add</Button>
          <Button variant="outlined" onClick={props.handleClose} autoFocus>Exit</Button>
        </div>
      </Box>
    </Modal>
  );
};
ModalAddWallet.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  auth: PropTypes.any,
};
export default ModalAddWallet;
