import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import LoginPage from './Components/Login/LoginPage';
import RegistrPage  from './Components/Registr/RegistrPage';

import RequireAuth from './hoc/RequireAuth';
import {AuthProvider} from './hoc/AuthProvider';


function App() {
  const [login, setLogin] = useState(window.localStorage.getItem('login') || 'fdf');
  const [password, setPassword] = useState(window.localStorage.getItem('password') || 'fdf');

  useEffect(() => {
    setLogin(window.localStorage.getItem('login'));
    setPassword(window.localStorage.getItem('password'));
  }, []);

  useEffect(() => {
    window.localStorage.setItem('login', login);
    window.localStorage.setItem('password', password);
  }, [login, password]);


  return (
    <div className="App">
        
      <AuthProvider>
        <Routes>
          <Route path='homepage' element={
            <RequireAuth>
              <HomePage login={login} password={password}/>
            </RequireAuth>}>
          </Route>
          <Route path='/' element={<LoginPage setLogin={setLogin} setPassword={setPassword}/>}></Route>        
        </Routes>
        </AuthProvider> 
    </div>
  );
}

export default App;
