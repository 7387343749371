import React, { useState, useEffect, useRef } from 'react';
import style from '../../HomePage.module.css';
import { styled } from '@mui/material/styles';
import base64 from 'base-64';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { Theme, useTheme } from '@mui/material/styles';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MyPagination from '../../Components/MyPagination/MyPagination';
import { REACT_APP_API_ENDPOINT } from '../../conf';

const styleModalCalendar = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function CalendarPages({ login, password }) {

    const navigate = useNavigate();
    const theme = useTheme();
    const goBack = () => {
        navigate('/')
        window.localStorage.setItem('login', '')
        window.localStorage.setItem('password', '')
    };
    const [openModalMovementsTokenCalendar, setOpenModalMovementsTokenCalendar] = useState(false);
    const [sortData, setSortData] = useState([]);
    const [movementsCalendar, setMovementsCalendar] = useState([]);
    const [timestampStart, setTimestampStart] = useState(new Date() - 86400000);
    const [timestampEnd, setTimestampEnd] = useState(new Date());
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [valueDate, setValueDate] = useState('');
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPerPage] = useState(4);
    const lastPageIndex = currentPage * currentPerPage;
    const firstPageIndex = lastPageIndex - currentPerPage;
    const CalendarPagination = movementsCalendar.slice(firstPageIndex, lastPageIndex);

    const paginate = pageNumber => setCurrentPage(pageNumber)

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "multipart/form-data");

    function movementsTokensCalendar(
        dataroomUrl,
        etherscanUrl,
        description,
        date,
        hash,
        to,
        value
    ) {
        return { description, date, hash, to, value };
    };
    function movementTokenCalendar(address) {
        setOpenModalMovementsTokenCalendar(true)
        let graphqlmovementscalendar = JSON.stringify({
            query: `{movements(address: "${address.address}", offset: 0,   limit: 5 ) {description,  date,   hash,   from { address, name },   to { address, name, releaseTypeName, dataroomUrl, etherscanUrl, number }, value { eth } }}`,
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlmovementscalendar,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setMovementsCalendar(res.data.data.movements) })
            .catch(err => console.log(err))
        if (movementsCalendar.length) {
            movementsCalendar?.map((item, index) => movementsTokensCalendar((item.dataroomUrl,item.etherscanUrl,item.description, item.date, item.hash, item.to, item.value)));
        }
    }
    function sortingDate() {
        let dateStart = timestampStart.toISOString()
        let dateEnd = timestampEnd.toISOString()
        let graphqlSortingTwo = JSON.stringify({
            query: `{
                     claims(
                        sortBy: TIMESTAMP, sortOrder: DESC,
                        dateStart: "${dateStart}",
                        dateEnd: "${dateEnd}",
                        offset: 0,
                        limit: 100
                        ) {
                          date,
                          hash,
                          to { address, name, releaseTypeName, dataroomUrl, etherscanUrl, number }
                          value { eth }
                        }
                     }`,
            variables: {}
        }, myHeaders)
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlSortingTwo,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setSortData(res.data.data.claims, 'res') })
            .catch(error => console.log('error', error))
    }
    useEffect(() => {
}, [])
    return (
        <div className={style.all_content}>
            <div className={style.table}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ padding: '3px' }} align="center">Date</StyledTableCell>
                                <StyledTableCell sx={{ padding: '3px' }} align="center">Hash</StyledTableCell>
                                <StyledTableCell sx={{ padding: '3px' }} align="center">Number</StyledTableCell>
                                <StyledTableCell sx={{ padding: '3px' }} align="center">To</StyledTableCell>
                                <StyledTableCell sx={{ padding: '3px' }} align="center">Name</StyledTableCell>
                                <StyledTableCell sx={{ padding: '3px' }} align="center">Tokens Claimed</StyledTableCell>
                                <StyledTableCell sx={{ padding: '3px' }} align="center">Category</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell sx={{ padding: '3px' }} colSpan={7} align="center">
                                    <div className={style.datepicker}>
                                        <DatePicker
                                            selected={timestampStart}
                                            onChange={(date) => setTimestampStart(date)}
                                            selectsStart
                                            showTimeSelect
                                            startDate={timestampStart}
                                            endDate={timestampEnd}
                                            timeFormat="HH:mm"
                                            timeIntervals={1}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm "
                                        />
                                        <DatePicker
                                            selected={timestampEnd}
                                            onChange={(date) => setTimestampEnd(date)}
                                            selectsEnd
                                            showTimeSelect
                                            startDate={timestampStart}
                                            endDate={timestampEnd}
                                            minDate={timestampStart}
                                            timeFormat="HH:mm"
                                            timeIntervals={1}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm "
                                        />
                                        <Button onClick={sortingDate} variant="contained" color="success">Apply</Button>
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                            {sortData && sortData?.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell sx={{ padding: '3px' }} align="center">{row.date}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px' }} align="center">{row.hash}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', cursor: 'pointer' }} align="center"><a href={row.to.dataroomUrl} target="_blank">{row.to.number}</a></StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="item" sx={{ padding: '3px', cursor: 'pointer', color: '#ffa726' }}><a href={row.to.etherscanUrl} target="_blank">{row.to.address}</a></StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px' }} align="center">{row.to.name}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px' }}>{row.value.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px' }} align="center">{row.to.releaseTypeName}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div >
            <div>
                <Modal
                    align="center"
                    open={openModalMovementsTokenCalendar}
                    onClose={() => setOpenModalMovementsTokenCalendar(false)}
                    aria-labelledby="modal-modal"
                    aria-describedby="modal-modal-description">
                    <Box sx={styleModalCalendar }>
                        <Typography id="modal-modal" variant="h6" component="h2" align="center" sx={{ mb: 3 }}>
                            Movements Token
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 250, }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Description</StyledTableCell>
                                        <StyledTableCell align="center">Date</StyledTableCell>
                                        <StyledTableCell align="center">Hash</StyledTableCell>
                                        <StyledTableCell align="center">Number</StyledTableCell>
                                        <StyledTableCell align="center">To</StyledTableCell>
                                        <StyledTableCell align="center">Name</StyledTableCell>
                                        <StyledTableCell align="center">Value</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {movementsCalendar && CalendarPagination?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center">{row.description}</StyledTableCell>
                                            <StyledTableCell align="center">{row.date}</StyledTableCell>
                                            <StyledTableCell align="center">{row.hash}</StyledTableCell>
                                            <StyledTableCell align="center">{row.to?.number}</StyledTableCell>
                                            <StyledTableCell align="center">{row.to?.address}</StyledTableCell>
                                            <StyledTableCell align="center">{row.to?.name}</StyledTableCell>
                                            <StyledTableCell>{row.value.eth}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <MyPagination
                            currentPerPage={currentPerPage}
                            totalMovement={movementsCalendar.length}
                            paginate={paginate}
                        />
                        <div className={style.btns_add_exit}>
                            <Button onClick={() => setOpenModalMovementsTokenCalendar(false)} sx={{ mt: 5 }} variant="outlined">Ok</Button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

