import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { REACT_APP_API_ENDPOINT } from '../../conf';
import styles from './Comment.module.css';

const CommentPlace = (props) => {

    const [newText, setNewText] = useState('');
    const [textError, setTextError] = useState(false);

    function GetComment() {
        axios.post(`${REACT_APP_API_ENDPOINT}`, {
            query: `query {getGlobalComment}`
        }, { headers: { 'Authorization': 'Basic ' + props.auth }})
            .then(res => {
                console.log(res)
                setNewText(res.data.data.getGlobalComment);
            })
            .catch(err => console.log(err))
    };
    function UpdateComment(newTextS) {
        axios.post(`${REACT_APP_API_ENDPOINT}`, {
            query: `mutation { updateGlobalComment(content: ${JSON.stringify(newTextS)})}`,
        }, { headers: { 'Authorization': 'Basic ' + props.auth }})
            .then(res => {
                console.log(res)
                if ('errors' in res.data)
                {
                    console.log('error', res.data);
                    setTextError(true);
                } else {
                    setTextError(false);
                }
            })
            .catch(err => console.log(err))
    };

    useEffect(() => {
        GetComment();
    }, [])

    return (
        <div className={styles.commentDiv}>
            { props.flagReadOnly ? (
                    <textarea
                    disabled
                    className={styles.commentStyle}
                    value={newText}
                    name="comment"
                    placeholder="Comment"
                    cols="40"
                    rows="3"
                    onChange={e =>{
                        console.log(e.target.value, 'e.target.value');
                        setNewText(e.target.value);
                        UpdateComment(e.target.value);
                    }}
                ></textarea>
                ) : (
                <textarea
                    className={styles.commentStyle}
                    value={newText}
                    name="comment"
                    placeholder="Comment"
                    cols="40"
                    rows="3"
                    onChange={e =>{
                        console.log(e.target.value, 'e.target.value');
                        setNewText(e.target.value);
                        UpdateComment(e.target.value);
                    }}
                ></textarea>
            )}
            
            {!props.flagReadOnly && <Button onClick={()=>UpdateComment(newText)} sx={{ mr: 15, margin: '15px', }} variant="outlined">Save</Button>}
        </div>
    )
}
export default CommentPlace;