import React, { useState, useEffect, useRef } from 'react';
import style from './TokenHoldersPages.module.css';
import { styled } from '@mui/material/styles';
import base64 from 'base-64';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { Theme, useTheme } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchIcon from '@mui/icons-material/Search';
import FormGroup from '@mui/material/FormGroup';
import { RadioGroup, Radio } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import "react-datepicker/dist/react-datepicker.css";
import MyPagination from '../../Components/MyPagination/MyPagination';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { REACT_APP_API_ENDPOINT } from '../../conf';
import FilterInput from '../../Components/FilterInput/FilterInput';

const stylesChangeName = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const stylesButtonDeleteWallets = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const stylesModalMovementsToken = {
    fontSize: 11,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1560,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function TokenHoldersTestPages({ login, password, flagReadOnly }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const goBack = () => {
        navigate('/')
        window.localStorage.setItem('login', '')
        window.localStorage.setItem('password', '')
    };
    const [opens, setOpens] = useState(false);
    const [openMovementsToken, setOpenMovementsToken] = useState(false);
    const [deletes, setDeletes] = useState(false);
    const [data, setData] = useState([]);
    const [order, setOrder] = useState(false);
    const [numberOrder, setNumberOrder] = useState(false);
    const [orderCWebBalanse, setOrderCWebBalanse] = useState(false);
    const [orderCWebAllocation, setOrderCWebAllocation] = useState(false);
    const [orderTotalAmount, setOrderTotalAmount] = useState(false);
    const [orderClaimedAmount, setOrderClaimedAmount] = useState(false);
    const [orderClaimedNow, setOrderClaimedNow] = useState(false);
    const [orderUnclaimedAmount, setOrderUnclaimedAmount] = useState(false);
    const [addName, setAddName] = useState('');
    const [dataroomUrl, setDataroomUrl] = useState('');
    const [urlError, setUrlError] = useState(false);
    const [deleteId, setDeleteId] = useState(-1);
    const [movementsTokens, setMovementsTokens] = useState([]);
    const [getAddres, setGetAddress] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPerPage] = useState(4);
    const lastPageIndex = currentPage * currentPerPage;
    const firstPageIndex = lastPageIndex - currentPerPage;
    const movementsTokensPagination = movementsTokens.slice(firstPageIndex, lastPageIndex);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [cwebBalance, setCwebBalance] = useState('cwebBalance');
    const [cwebBalances, setCwebBalances] = useState('cwebBalance')
    const [totalAmount, setTotalAmount] = useState('totalAmount');
    const [totalAmounts, setTotalAmounts] = useState('totalAmount');
    const [claimedAmounts, setClaimedAmounts] = useState('claimedAmount');
    const [unclaimedAmounts, setUnclaimedAmounts] = useState('unclaimedAmount')
    const [searchAddress, setSearchAddress] = useState('');
    const [searchName, setSearchName] = useState('');
    const [searchEthCweb, setSearchEthCweb] = useState('');
    const [searchEthTotal, setSearchEthTotal] = useState('');
    const [searchEthClaimed, setSearchEthClaimed] = useState('');
    const [searchEthUnclaimed, setSearchEthUnclaimed] = useState('');
    const [dataAmount, setDataAmount] = useState(undefined);
    const [dataUnclaimedAmount, setDataUnclaimedAmount] = useState('');
    const [dataClaimedAmount, setDataClaimedAmount] = useState('');
    const [dataClaimedAmountType, setDataClaimedAmountType] = useState('');
    const [dataUnclaimedAmountType, setDataUnclaimedAmountType] = useState('');

    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);
    const [checked4, setChecked4] = useState(false);
    const [checked5, setChecked5] = useState(false);
    const [checked6, setChecked6] = useState(false);
    const [checked7, setChecked7] = useState(false);
    const [checked8, setChecked8] = useState(false);
    const [checked9, setChecked9] = useState(false);
    const [categoryFilter, setCategoryFilter] = useState(null);


    const handleSubmit = (event) => {
        event.preventDefault();
    }
    const handleSubmits = (event) => {
        event.preventDefault();
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "multipart/form-data");

    const deleteIte = (id) => {
        setDeletes(true);
        setDeleteId(id);
    }
    function createData(
        dataroomUrl,
        etherscanUrl,
        address,
        name,
        releaseType,
        cwebBalance,
        cwebAllocation,
        totalAmount,
        claimedAmount,
        unclaimedAmount
    ) {
        return { address, name, releaseType, cwebBalance, cwebAllocation, totalAmount, claimedAmount, unclaimedAmount };
    };
    function movementsTokenS(
        description,
        date,
        hash,
        from,
        to,
        value
    ) {
        return { description, date, hash, from, to, value };
    };
    function addNames() {
        if (addName.trim().length) {
            let query = `mutation {
                updateWallet( address: "${getAddres}", name: "${addName}", dataroomUrl: "${dataroomUrl}"){ address }}`
            if (dataroomUrl === '' || dataroomUrl === null) {
                query = `mutation {
                    updateWallet( address: "${getAddres}", name: "${addName}"){ address }}`
            }

            axios.post(`${REACT_APP_API_ENDPOINT}`, {
                query
            }, { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
                .then(res => {
                    if ('errors' in res.data)
                    {
                        console.log('error', res.data);
                        setUrlError(true);
                    }
                    else
                    {
                        setUrlError(false);
                        setOpens(false);
                        getMainData();
                        getMainDataAmount();
                        getMainDataUnclaimedAmount();
                        getMainDataClaimedAmount();
                        getMainDataClaimedAmountType();
                        getMainDataUnclaimedAmountType();
                    }
                })
                .catch(err =>console.log(err))
        }
    };
    function changeName(name, address, url) {
        setOpens(true);
        setDataroomUrl(url);
        setAddName(name);
        setGetAddress(address);
    };
    function getMainData() {
        var graphql = JSON.stringify({
            query: "{wallets {dataroomUrl, etherscanUrl, number, address, name, releaseType, cwebBalance { eth },   cwebAllocation,   totalAmount { eth },   claimedAmount { eth }, unclaimedAmount { eth }, claimableNow { eth } }}",
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphql,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                console.log(res, 'res')
                setData(res.data.data.wallets)
            })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.releaseType, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };
    function getMainDataAmount() {
        var graphql = JSON.stringify({
            query: "{totalAmountPerReleaseType { total {eth} teamAndAdvisors {eth} strategicRound {eth} strategicPartners {eth} publicSale {eth} seedRound {eth} privateRound {eth} miningReserve {eth} foundersAndEarlyContributors {eth} companyReserve {eth}}, claimableNow { eth } }",
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphql,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                console.log(res,'res-totalAmountPerReleaseType')
                console.log(res.data.data.totalAmountPerReleaseType,'data-totalAmountPerReleaseType')
                setDataAmount(res.data.data.totalAmountPerReleaseType)
            })
            .catch(err => console.log(err))
    };
    function getMainDataClaimedAmount() {
        var graphql = JSON.stringify({
            query: "{totalClaimedAmount {eth} }",
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphql,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                console.log(res,'res-totalClaimedAmount')
                console.log(res.data.data.totalClaimedAmount.eth,'data-totalClaimedAmount')
                setDataClaimedAmount(res.data.data.totalClaimedAmount.eth)
            })
            .catch(err => console.log(err))
    };
    function getMainDataUnclaimedAmount() {
        var graphql = JSON.stringify({
            query: "{totalUnclaimedAmount {eth} }",
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphql,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                console.log(res,'res-totalUnclaimedAmount')
                console.log(res.data.data.totalUnclaimedAmount.eth,'data-totalUnclaimedAmount')
                setDataUnclaimedAmount(res.data.data.totalUnclaimedAmount.eth)
            })
            .catch(err => console.log(err))
    };
    function getMainDataClaimedAmountType() {
        var graphql = JSON.stringify({
            query: "{claimedAmountPerReleaseType { total {eth} teamAndAdvisors {eth} strategicRound {eth} strategicPartners {eth} publicSale {eth} seedRound {eth} privateRound {eth} miningReserve {eth} foundersAndEarlyContributors {eth} companyReserve {eth}} }",
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphql,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                console.log(res,'res-claimedAmountPerReleaseType')
                console.log(res.data.data.claimedAmountPerReleaseType,'data-claimedAmountPerReleaseType')
                setDataClaimedAmountType(res.data.data.claimedAmountPerReleaseType)
            })
            .catch(err => console.log(err))
    };
    function getMainDataUnclaimedAmountType() {
        var graphql = JSON.stringify({
            query: "{unclaimedAmountPerReleaseType { total {eth} teamAndAdvisors {eth} strategicRound {eth} strategicPartners {eth} publicSale {eth} seedRound {eth} privateRound {eth} miningReserve {eth} foundersAndEarlyContributors {eth} companyReserve {eth}} }",
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphql,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                console.log(res,'res-unclaimedAmountPerReleaseType')
                console.log(res.data.data.unclaimedAmountPerReleaseType,'data-unclaimedAmountPerReleaseType')
                setDataUnclaimedAmountType(res.data.data.unclaimedAmountPerReleaseType)
            })
            .catch(err => console.log(err))
    };
    // [ASC | DESC]
    function sortByNumber(name) {
        setNumberOrder(!numberOrder);
        setOrder(false);
        setOrderClaimedAmount(false);
        setOrderUnclaimedAmount(false);
        setOrderClaimedNow(false);
        let graphqlSortByNumber;
        if (categoryFilter===null)
            graphqlSortByNumber = JSON.stringify({
                query: `{\r\n  wallets(\r\n filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:${categoryFilter}}    sortBy: ${name},\r\n  sortOrder: ${numberOrder ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
                variables: {}
            })
        else
            graphqlSortByNumber = JSON.stringify({
                query: `{\r\n  wallets(\r\n filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[${categoryFilter}]}    sortBy: ${name},\r\n  sortOrder: ${numberOrder ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlSortByNumber,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                setData(res.data.data.wallets)

            })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.releaseType, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.claimedAmount, item.unclaimedAmount));
        }
    }

    function sortByName(name) {
        setOrder(!order);
        setNumberOrder(false);
        setOrderClaimedAmount(false);
        setOrderUnclaimedAmount(false);
        setOrderClaimedNow(false);
        let graphqlSortByName;
        if (categoryFilter===null)
            graphqlSortByName = JSON.stringify({
                query: `{\r\n  wallets(\r\n filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:${categoryFilter},},    sortBy: ${name},\r\n  sortOrder: ${order ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
                variables: {}
            })
        else
            graphqlSortByName = JSON.stringify({
                query: `{\r\n  wallets(\r\n filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[${categoryFilter}],},    sortBy: ${name},\r\n  sortOrder: ${order ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
                variables: {}
            })

        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlSortByName,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                setData(res.data.data.wallets)

            })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.releaseType, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.claimedAmount, item.unclaimedAmount));
        }
    }
    function sortCWebBalance(cwebBalance) {
        setOrderCWebBalanse(!orderCWebBalanse)
        var graphqlSortCWebBalance = JSON.stringify({
            query: `{\r\n  wallets(\r\n  filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:"${categoryFilter}"},  sortBy: ${cwebBalance},\r\n    sortOrder: ${orderCWebBalanse ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlSortCWebBalance,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                setData(res.data.data.wallets)

            })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.releaseType, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.claimedAmount, item.unclaimedAmount));
        }
    }
    function sortCWebAllocations(cwebAllocation) {
        setOrderCWebAllocation(!orderCWebAllocation)
        var graphqlCWebAllocation = JSON.stringify({
            query: `{\r\n  wallets(\r\n  filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:"${categoryFilter}"},   sortBy: ${cwebAllocation},\r\n    sortOrder: ${orderCWebAllocation ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlCWebAllocation,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                setData(res.data.data.wallets)
            })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.releaseType, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.claimedAmount, item.unclaimedAmount));
        }
    }
    function sortTotalAmounts(totalAmount) {
        setOrderTotalAmount(!orderTotalAmount)
        var graphqlTotalAmount = JSON.stringify({
            query: `{\r\n  wallets(\r\n filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:"${categoryFilter}"},   sortBy: ${totalAmount},\r\n    sortOrder: ${orderTotalAmount ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlTotalAmount,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                setData(res.data.data.wallets)
            })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.releaseType, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.claimedAmount, item.unclaimedAmount));
        }
    }
    function sortClaimedNow(claimedAmount) {
        setOrderClaimedNow(!orderClaimedNow);
        setNumberOrder(false);
        setOrder(false);
        setOrderUnclaimedAmount(false);
        setOrderClaimedAmount(false);
        let graphqlClaimedAmount;
        if (categoryFilter===null)
            graphqlClaimedAmount = JSON.stringify({
                query: `{\r\n  wallets(\r\n filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:${categoryFilter}},    sortBy: ${claimedAmount},\r\n    sortOrder: ${orderClaimedNow ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
                variables: {}
            })
        else
            graphqlClaimedAmount = JSON.stringify({
                query: `{\r\n  wallets(\r\n filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[${categoryFilter}]},    sortBy: ${claimedAmount},\r\n    sortOrder: ${orderClaimedNow ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlClaimedAmount,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                setData(res.data.data.wallets)
            })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.releaseType, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.claimedAmount, item.unclaimedAmount));
        }
    }
    function sortClaimedAmounts(claimedAmount) {
        setOrderClaimedAmount(!orderClaimedAmount);
        setNumberOrder(false);
        setOrder(false);
        setOrderUnclaimedAmount(false);
        setOrderClaimedNow(false);
        let graphqlClaimedAmount;
        if (categoryFilter===null)
            graphqlClaimedAmount = JSON.stringify({
                query: `{\r\n  wallets(\r\n filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:${categoryFilter}},    sortBy: ${claimedAmount},\r\n    sortOrder: ${orderClaimedAmount ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
                variables: {}
            })
        else
            graphqlClaimedAmount = JSON.stringify({
                query: `{\r\n  wallets(\r\n filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[${categoryFilter}]},    sortBy: ${claimedAmount},\r\n    sortOrder: ${orderClaimedAmount ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlClaimedAmount,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                setData(res.data.data.wallets)
            })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.releaseType, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.claimedAmount, item.unclaimedAmount));
        }
    }
    function sortUnclaimedAmounts(unclaimedAmount) {
        setOrderUnclaimedAmount(!orderUnclaimedAmount);
        setNumberOrder(false);
        setOrder(false);
        setOrderClaimedAmount(false);
        setOrderClaimedNow(false);
        
        let graphqlUnclaimedAmount;
        if (categoryFilter===null)
            graphqlUnclaimedAmount = JSON.stringify({
                query: `{\r\n  wallets(\r\n  filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:${categoryFilter}},  sortBy: ${unclaimedAmount},\r\n    sortOrder: ${orderUnclaimedAmount ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
                variables: {}
            })
        else
            graphqlUnclaimedAmount = JSON.stringify({
                query: `{\r\n  wallets(\r\n  filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[${categoryFilter}]},  sortBy: ${unclaimedAmount},\r\n    sortOrder: ${orderUnclaimedAmount ? 'ASC' : 'DESC'},\r\n    ) { etherscanUrl,\r\n   dataroomUrl,\r\n    number,\r\n    address,\r\n    name,\r\n    releaseType,\r\n    cwebBalance { eth },\r\n    cwebAllocation,\r\n    totalAmount { eth },\r\n    claimedAmount { eth },\r\n    unclaimedAmount { eth }\r\n, claimableNow { eth }  }\r\n}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlUnclaimedAmount,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                setData(res.data.data.wallets)
            })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.releaseType, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.claimedAmount, item.unclaimedAmount));
        }
    }
    //END [ASC | DESC]
    function deleteButton(item) {
        if (item) {
            let graphqldelete = JSON.stringify({
                query: `mutation { removeWallet(address: \"${item}\") }`,
                variables: {}
            }, myHeaders)
            axios.post(`${REACT_APP_API_ENDPOINT}`, graphqldelete, { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
                .then(res => {
                    console.log('Work')
                    getMainData();
                    getMainDataAmount();
                    getMainDataUnclaimedAmount();
                    getMainDataClaimedAmount();
                    setDeletes(false);
                    getMainDataClaimedAmountType();
                    getMainDataUnclaimedAmountType();
                })
                .catch(error => console.log('error', error))
        }
    }
    function movementsToken(address) {
        setOpenMovementsToken(true)
        let graphqlCWebBalance = JSON.stringify({
            query: `{movements(address: "${address}", offset: 0,   limit: 100 ) {description,  date,   hash,   from { address, number },   to { address, number }, value { eth } }}`,
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlCWebBalance,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setMovementsTokens(res.data.data.movements) })
            .catch(err => console.log(err))
        if (movementsTokens.length) {
            movementsTokens?.map((item, index) => movementsTokenS((item.description, item.date, item.hash, item.to, item.value)));
        }
    }

    function searchAllCategory() {
        var graphqlSearchAddress = JSON.stringify({
            query: `{wallets( filter: { address: "${searchAddress}",  name: "${searchName}", releaseTypes:"${categoryFilter}"}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }}}`,
            ariables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlSearchAddress,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };

    function searchAddresses(value) {
        setSearchAddress(value);
        let graphqlSearchAddress
        if (categoryFilter===null)
            graphqlSearchAddress = JSON.stringify({
                query: `{wallets(filter: { address: "${value}",  name: "${searchName}", releaseTypes:${categoryFilter}}) {dataroomUrl, etherscanUrl,number, address, name, releaseType,cwebBalance { eth }, cwebAllocation, totalAmount { eth }, claimedAmount { eth }, unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlSearchAddress = JSON.stringify({
                query: `{wallets(filter: { address: "${value}",  name: "${searchName}", releaseTypes:[${categoryFilter}]}) {dataroomUrl, etherscanUrl,number, address, name, releaseType,cwebBalance { eth }, cwebAllocation, totalAmount { eth }, claimedAmount { eth }, unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlSearchAddress,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };
    function searchNames(value) {
        setSearchName(value);
        let graphqlSearchName
        if (categoryFilter===null)
            graphqlSearchName = JSON.stringify({
                query: `{wallets(filter: { address: "${searchAddress}", name: "${value}", releaseTypes:${categoryFilter}}) {dataroomUrl, etherscanUrl,number,address,name, releaseType, cwebBalance { eth }, cwebAllocation, totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlSearchName = JSON.stringify({
                query: `{wallets(filter: { address: "${searchAddress}", name: "${value}", releaseTypes:[${categoryFilter}]}) {dataroomUrl, etherscanUrl,number,address,name, releaseType, cwebBalance { eth }, cwebAllocation, totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
            
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlSearchName,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .then(res => { console.log(res.data.data, 'data.data.wallets') })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };
    function seedVariant(value) {
        let graphqlPrivateTrue
        if (value)
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[SEED_ROUND]}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}"}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlPrivateTrue,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };
    function privateVariant(value) {
        let graphqlPrivateTrue
        if (value)
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[PRIVATE_ROUND]}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}"}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlPrivateTrue,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };
    function strategicVariant(value) {
        let graphqlPrivateTrue
        if (value)
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[STRATEGIC_ROUND]}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}"}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlPrivateTrue,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };
    function taVariant(value) {
        let graphqlPrivateTrue
        if (value)
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[TEAM_AND_ADVISORS]}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}"}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlPrivateTrue,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };
    function partnersVariant(value) {
        let graphqlPrivateTrue
        if (value)
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[STRATEGIC_PARTNERS]}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}"}) {dataroomUrl, etherscanUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlPrivateTrue,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };
    function fecVariant(value) {
        let graphqlPrivateTrue
        if (value)
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[FOUNDERS_AND_EARLY_CONTRIBUTORS]}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}"}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlPrivateTrue,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };
    function mrVariant(value) {
        let graphqlPrivateTrue
        if (value)
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[MINING_RESERVE]}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}"}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlPrivateTrue,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };
    function crVariant(value) {
        let graphqlPrivateTrue
        if (value)
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[COMPANY_RESERVE]}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}"}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlPrivateTrue,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };

    function psVariant(value) {
        let graphqlPrivateTrue
        if (value)
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}", releaseTypes:[PUBLIC_SALE]}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        else
            graphqlPrivateTrue = JSON.stringify({
                query: `{wallets( filter: {name: "${searchName}", address: "${searchAddress}"}) {dataroomUrl, etherscanUrl,number,address, name,   releaseType,    cwebBalance { eth },   cwebAllocation,   totalAmount { eth }, claimedAmount { eth },  unclaimedAmount { eth }, claimableNow { eth }}}`,
                variables: {}
            })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlPrivateTrue,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => { setData(res.data.data.wallets) })
            .catch(err => console.log(err))
        if (data.length) {
            data?.map((item, index) => createData(item.dataroomUrl, item.etherscanUrl, item.number, item.address, item.name, item.cwebBalance, item.cwebAllocation, item.totalAmount, item.unclaimedAmount, item.claimedAmount));
        }
    };

    function ButtonClear() {
        setCwebBalances('cwebBalance');
        setTotalAmounts('totalAmount');
        setClaimedAmounts('claimedAmount');
        setUnclaimedAmounts('unclaimedAmount');
        /*setChecked1(false);
        setChecked2(false);
        setChecked3(false);
        setChecked4(false);
        setChecked5(false);
        setChecked6(false);
        setChecked7(false);
        setChecked8(false);*/
        var graphql = JSON.stringify({
            query: "{ wallets(filter: { } ) {dataroomUrl, etherscanUrl,number, address,   name,    releaseType,   cwebBalance { eth },    cwebAllocation,   totalAmount { eth },  claimedAmount { eth }, unclaimedAmount { eth }, claimableNow { eth }}}",
            variables: {}
        })
        axios.post(`${REACT_APP_API_ENDPOINT}`, graphql,
            { headers: { 'Authorization': 'Basic ' + base64.encode(login + ":" + password) } })
            .then(res => {
                setData(res.data.data.wallets)
                setSearchAddress('');
                setSearchName('');
                setCategoryFilter(null);
                setSearchEthCweb('');
                setSearchEthTotal('');
                setSearchEthClaimed('');
                setSearchEthUnclaimed('');
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getMainData();
        getMainDataAmount();
        getMainDataUnclaimedAmount();
        getMainDataClaimedAmount();
        getMainDataClaimedAmountType();
        getMainDataUnclaimedAmountType();
    }, [])

    return (
        <div className={style.all_content}>
            <div className={style.all_filter_and_select}>
                <FilterInput
                    classname1={style.style_form}
                    classname2={style.input_search_address}
                    classname3={style.button_search}
                    handleSubmit={handleSubmit}
                    searchClick={searchAddresses}
                    placeholder="Address"
                    personalIcon="Manage"
                />
                <FilterInput
                    classname1={style.style_form}
                    classname2={style.input_search}
                    classname3={style.button_search}
                    handleSubmit={handleSubmits}
                    searchClick={searchNames}
                    placeholder="Name"
                    personalIcon="Person"
                />
                <div
                    style={{display: "contents"}}
                >
                    <FormControlLabel sx={{ ml: 1 }} control={<Checkbox checked={checked1} onChange={() => {
                            setChecked1(!checked1);
                            seedVariant(!checked1);
                            if (!checked1) setCategoryFilter('SEED_ROUND');
                            else setCategoryFilter(null);
                            setChecked2(false);
                            setChecked3(false);
                            setChecked4(false);
                            setChecked5(false);
                            setChecked6(false);
                            setChecked7(false);
                            setChecked8(false);
                            setChecked9(false);
                        }}/>} label="Seed Round" />
                    <FormControlLabel sx={{ ml: 1 }} control={<Checkbox checked={checked2} onChange={() => {
                            setChecked2(!checked2);
                            privateVariant(!checked2);
                            if (!checked2) setCategoryFilter('PRIVATE_ROUND');
                            else setCategoryFilter(null);
                            setChecked1(false);
                            setChecked3(false);
                            setChecked4(false);
                            setChecked5(false);
                            setChecked6(false);
                            setChecked7(false);
                            setChecked8(false);
                            setChecked9(false);
                        }}/>} label="Private Round" />
                    <FormControlLabel sx={{ ml: 1 }} control={<Checkbox checked={checked3} onChange={() => {
                            setChecked3(!checked3);
                            strategicVariant(!checked3);
                            if (!checked3) setCategoryFilter('STRATEGIC_ROUND');
                            else setCategoryFilter(null);
                            setChecked1(false);
                            setChecked2(false);
                            setChecked4(false);
                            setChecked5(false);
                            setChecked6(false);
                            setChecked7(false);
                            setChecked8(false);
                            setChecked9(false);
                        }}/>} label="Strategic Round" />
                    {/*<FormControlLabel sx={{ ml: 1 }} control={<Checkbox checked={checked9} onChange={() => {
                            setChecked9(!checked9);
                            psVariant(!checked9);
                            if (!checked9) setCategoryFilter('PUBLIC_SALE');
                            else setCategoryFilter(null);
                            setChecked1(false);
                            setChecked2(false);
                            setChecked3(false);
                            setChecked4(false);
                            setChecked5(false);
                            setChecked6(false);
                            setChecked7(false);
                            setChecked8(false);
                        }}/>} label="Public Sale" />*/}
                    <FormControlLabel sx={{ ml: 1 }} control={<Checkbox checked={checked4} onChange={() => {
                            setChecked4(!checked4);
                            taVariant(!checked4);
                            if (!checked4) setCategoryFilter('TEAM_AND_ADVISORS');
                            else setCategoryFilter(null);
                            setChecked1(false);
                            setChecked2(false);
                            setChecked3(false);
                            setChecked5(false);
                            setChecked6(false);
                            setChecked7(false);
                            setChecked8(false);
                            setChecked9(false);
                        }}/>} label="Team/Advisors" />
                    <FormControlLabel sx={{ ml: 1 }} control={<Checkbox checked={checked5} onChange={() => {
                            setChecked5(!checked5);
                            partnersVariant(!checked5);
                            if (!checked5) setCategoryFilter('STRATEGIC_PARTNERS');
                            else setCategoryFilter(null);
                            setChecked1(false);
                            setChecked2(false);
                            setChecked3(false);
                            setChecked4(false);
                            setChecked6(false);
                            setChecked7(false);
                            setChecked8(false);
                            setChecked9(false);
                        }}/>} label="Strategic Partners" />
                    <FormControlLabel sx={{ ml: 1 }} control={<Checkbox checked={checked6} onChange={() => {
                            setChecked6(!checked6);
                            fecVariant(!checked6);
                            if (!checked6) setCategoryFilter('FOUNDERS_AND_EARLY_CONTRIBUTORS');
                            else setCategoryFilter(null);
                            setChecked1(false);
                            setChecked2(false);
                            setChecked3(false);
                            setChecked4(false);
                            setChecked5(false);
                            setChecked7(false);
                            setChecked8(false);
                            setChecked9(false);
                        }}/>} label="Founders/EC" />
                    <FormControlLabel sx={{ ml: 1 }} control={<Checkbox checked={checked7} onChange={() => {
                            setChecked7(!checked7);
                            mrVariant(!checked7);
                            if (!checked7) setCategoryFilter('MINING_RESERVE');
                            else setCategoryFilter(null);
                            setChecked1(false);
                            setChecked2(false);
                            setChecked3(false);
                            setChecked4(false);
                            setChecked5(false);
                            setChecked6(false);
                            setChecked8(false);
                            setChecked9(false);
                        }}/>} label="Mining Reserve" />
                    <FormControlLabel sx={{ ml: 1 }} control={<Checkbox checked={checked8} onChange={() => {
                            setChecked8(!checked8);
                            crVariant(!checked8);
                            if (!checked8) setCategoryFilter('COMPANY_RESERVE');
                            else setCategoryFilter(null);
                            setChecked1(false);
                            setChecked2(false);
                            setChecked3(false);
                            setChecked4(false);
                            setChecked5(false);
                            setChecked6(false);
                            setChecked7(false);
                            setChecked9(false);
                        }}/>} label="Company Reserve" />
                </div>
                <button className={style.btn_clear} onClick={() => { ButtonClear() }}>Clear<ClearIcon /></button>
            </div>
            <div className={style.table} >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell onClick={() => { sortByNumber('NUMBER') }} sx={{ minWidth:'110px', cursor: 'pointer', padding: '5px', textAlign: "center" }}>Number
                                { numberOrder ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }
                                </StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center">Address</StyledTableCell>
                                <StyledTableCell onClick={() => { sortByName('NAME') }} sx={{ minWidth:'150px', cursor: 'pointer', padding: '5px', textAlign: "center", align: "center" }}>Name
                                { order ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>Seed</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>Private</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>Strategic</StyledTableCell>    
                                {/*<StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>PS</StyledTableCell>*/}
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>TA</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>Partners</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>FEC</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>MR</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>CR</StyledTableCell>
                                <StyledTableCell onClick={() => { sortUnclaimedAmounts('UNCLAIMED_AMOUNT') }} sx={{ minWidth:'110px', cursor: 'pointer',  padding: '5px', textAlign: "center" }}>Unclaimed
                                { orderUnclaimedAmount ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }</StyledTableCell>
                                <StyledTableCell onClick={() => { sortClaimedAmounts('CLAIMED_AMOUNT') }} sx={{ minWidth:'110px', cursor: 'pointer', padding: '5px', textAlign: "center" }}>Claimed
                                { orderClaimedAmount ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }</StyledTableCell>
                                <StyledTableCell onClick={() => { sortClaimedNow('CLAIMABLE_NOW') }} sx={{ minWidth:'110px', cursor: 'pointer', padding: '5px', textAlign: "center" }}>Claimable Now
                                { orderClaimedNow ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }</StyledTableCell>
                                {!flagReadOnly && <StyledTableCell sx={{ padding: '5px' }} align="center">Update/Delete</StyledTableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.map((row, index) => (
                                <>
                                <StyledTableRow key={index}>
                                    <StyledTableCell sx={{ padding: '0px', 'text-align': 'center', cursor: 'pointer' }} align="center"><a href={row.dataroomUrl} target="_blank">{row.number}</a></StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="item" sx={{ padding: '0px', cursor: 'pointer', color: '#ffa726' }}><a href={row.etherscanUrl} target="_blank">{row.address}</a></StyledTableCell>
                                    {/*<StyledTableCell align="center" component="th" scope="item" sx={{ cursor: 'pointer', color: '#ffa726' }} onClick={() => movementsToken(row.address)}>{row.address}</StyledTableCell>*/}
                                    <StyledTableCell sx={{ padding: '0px', 'text-align': 'left' }} align="left">{row.name}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{row.releaseType==='SEED_ROUND' ? row.totalAmount.eth : '/'}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{row.releaseType==='PRIVATE_ROUND' ? row.totalAmount.eth : '/'}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{row.releaseType==='STRATEGIC_ROUND' ? row.totalAmount.eth : '/'}</StyledTableCell>
                                    {/*<StyledTableCell sx={{ padding: '0px' }} align="right">{row.releaseType==='PUBLIC_SALE' ? row.totalAmount.eth : '/'}</StyledTableCell>*/}
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{row.releaseType==='TEAM_AND_ADVISORS' ? row.totalAmount.eth : '/'}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{row.releaseType==='STRATEGIC_PARTNERS' ? row.totalAmount.eth : '/'}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{row.releaseType==='FOUNDERS_AND_EARLY_CONTRIBUTORS' ? row.totalAmount.eth : '/'}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px'}} align="right">{row.releaseType==='MINING_RESERVE' ? row.totalAmount.eth : '/'}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{row.releaseType==='COMPANY_RESERVE' ? row.totalAmount.eth : '/'}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{row.unclaimedAmount.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{row.claimedAmount.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{row.claimableNow.eth}</StyledTableCell>
                                    
                                    {!flagReadOnly && <StyledTableCell sx={{ padding: '0px'}} align="center" component="th" scope="item">
                                        <div className={style.buttonStyle}>
                                            <Button onClick={() => changeName(row.name, row.address, row.dataroomUrl)}>
                                                <CreateIcon />
                                            </Button>
                                            <Button onClick={() => deleteIte(row.address)} >
                                                <DeleteIcon color="action" />
                                            </Button>
                                        </div>
                                    </StyledTableCell>}
                                </StyledTableRow>
                                </>
                            ))}
                            {dataClaimedAmountType && (
                                <StyledTableRow sx={{backgroundColor: '#7BD552 !important' }}>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell align="center">Claimed</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataClaimedAmountType.seedRound.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataClaimedAmountType.privateRound.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataClaimedAmountType.strategicRound.eth}</StyledTableCell>
                                    {/*<StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataAmount.publicSale.eth}</StyledTableCell>*/}
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataClaimedAmountType.teamAndAdvisors.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataClaimedAmountType.strategicPartners.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataClaimedAmountType.foundersAndEarlyContributors.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataClaimedAmountType.miningReserve.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px'}} align="right">{dataClaimedAmountType.companyReserve.eth}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    {!flagReadOnly && <StyledTableCell></StyledTableCell>}
                                </StyledTableRow>
                            )}
                            {dataUnclaimedAmountType && (
                                <StyledTableRow sx={{backgroundColor: '#90E26B !important' }}>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell align="center">Unclaimed</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataUnclaimedAmountType.seedRound.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataUnclaimedAmountType.privateRound.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataUnclaimedAmountType.strategicRound.eth}</StyledTableCell>
                                    {/*<StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataAmount.publicSale.eth}</StyledTableCell>*/}
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataUnclaimedAmountType.teamAndAdvisors.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataUnclaimedAmountType.strategicPartners.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataUnclaimedAmountType.foundersAndEarlyContributors.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataUnclaimedAmountType.miningReserve.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px'}} align="right">{dataUnclaimedAmountType.companyReserve.eth}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    {!flagReadOnly && <StyledTableCell></StyledTableCell>}
                                </StyledTableRow>
                            )}
                            {dataAmount && (
                                <StyledTableRow sx={{backgroundColor: '#9DFC72 !important' }}>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell align="center">Total amount</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataAmount.seedRound.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataAmount.privateRound.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataAmount.strategicRound.eth}</StyledTableCell>
                                    {/*<StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataAmount.publicSale.eth}</StyledTableCell>*/}
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataAmount.teamAndAdvisors.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataAmount.strategicPartners.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataAmount.foundersAndEarlyContributors.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '3px', 'border-right-style': 'solid !important', 'border-right-width': '1px !important' }} align="right">{dataAmount.miningReserve.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px'}} align="right">{dataAmount.companyReserve.eth}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{dataUnclaimedAmount}</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '0px' }} align="right">{dataClaimedAmount}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    {!flagReadOnly && <StyledTableCell></StyledTableCell>}
                                </StyledTableRow>
                            )}
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell onClick={() => { sortByNumber('NUMBER') }} sx={{ minWidth:'110px', cursor: 'pointer', padding: '5px', textAlign: "center" }}>Number
                                { numberOrder ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }
                                </StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center">Address</StyledTableCell>
                                <StyledTableCell onClick={() => { sortByName('NAME') }} sx={{ minWidth:'150px', cursor: 'pointer', padding: '5px', textAlign: "center", align: "center" }}>Name
                                { order ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>Seed</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>Private</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>Strategic</StyledTableCell>    
                                {/*<StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>PS</StyledTableCell>*/}
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>TA</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>Partners</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>FEC</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>MR</StyledTableCell>
                                <StyledTableCell sx={{ padding: '5px' }} align="center" textAlign='center'>CR</StyledTableCell>
                                <StyledTableCell onClick={() => { sortUnclaimedAmounts('UNCLAIMED_AMOUNT') }} sx={{ minWidth:'110px', cursor: 'pointer',  padding: '5px', textAlign: "center" }}>Unclaimed
                                { orderUnclaimedAmount ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }</StyledTableCell>
                                <StyledTableCell onClick={() => { sortClaimedAmounts('CLAIMED_AMOUNT') }} sx={{ minWidth:'110px', cursor: 'pointer', padding: '5px', textAlign: "center" }}>Claimed
                                { orderClaimedAmount ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }</StyledTableCell>
                                <StyledTableCell onClick={() => { sortClaimedNow('CLAIMABLE_NOW') }} sx={{ minWidth:'110px', cursor: 'pointer', padding: '5px', textAlign: "center" }}>Claimable Now
                                { orderClaimedNow ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }</StyledTableCell>
                                {!flagReadOnly && <StyledTableCell sx={{ padding: '5px' }} align="center">Update/Delete</StyledTableCell>}
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </div>

            <div>
                <Modal
                    align="center"
                    open={opens}
                    onClose={() => setOpens(false)}
                    aria-labelledby="modal-modal"
                    aria-describedby="modal-modal-description">
                    <Box sx={stylesChangeName}>
                        <Typography id="modal-modal" variant="h6" component="h2" align="center">
                            Enter your name
                        </Typography>
                        <TextField
                            sx={{ m: 1, width: '45ch' }}
                            id="outlined-basic"
                            label="name"
                            value={addName}
                            onChange={e => setAddName(e.target.value)} />
                        <Typography id="modal-modal" variant="h6" component="h2" align="center">
                            Enter Data Room URL
                        </Typography>
                        <TextField
                            error = {urlError}
                            sx={{ m: 1, width: '45ch' }}
                            id="outlined-basic"
                            label="Data Room URL"
                            value={dataroomUrl}
                            onChange={e => setDataroomUrl(e.target.value)} />
                        <div className={style.btns_add_exit}>
                            <Button onClick={() => addNames()} sx={{ mr: 15 }} variant="outlined">Save change</Button>
                            <Button variant="outlined" onClick={() =>{
                                setUrlError(false);
                                setOpens(false);
                            }} autoFocus>Exit</Button>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    align="center"
                    open={deletes}
                    onClose={() => setDeletes(false)}
                    aria-labelledby="modal-modal"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={stylesButtonDeleteWallets}>
                        <Typography id="modal-modal" variant="h6" component="h2" align="center">
                            Are you sure you want to delete?
                        </Typography>
                        <div className={style.btns_add_exit}>
                            <Button onClick={() => deleteButton(deleteId)} sx={{ mr: 15, mt: 5 }} variant="outlined">Delete</Button>
                            <Button variant="outlined" onClick={() => setDeletes(false)} sx={{ mt: 5 }} autoFocus>Close</Button>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    align="center"
                    open={openMovementsToken}
                    onClose={() => setOpenMovementsToken(false)}
                    aria-labelledby="modal-modal"
                    aria-describedby="modal-modal-description">
                    <Box sx={stylesModalMovementsToken}>
                        <Typography id="modal-modal" variant="h6" component="h2" align="center" sx={{ mb: 3 }}>
                            Movements Token
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 250, }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Date</StyledTableCell>
                                        <StyledTableCell align="center">Description</StyledTableCell>
                                        <StyledTableCell align="center">Number</StyledTableCell>
                                        <StyledTableCell align="center">From</StyledTableCell>
                                        <StyledTableCell align="center">Hash</StyledTableCell>
                                        <StyledTableCell align="center">Number</StyledTableCell>
                                        <StyledTableCell align="center">To</StyledTableCell>
                                        <StyledTableCell align="center">Value</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {movementsTokens && movementsTokensPagination?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center">{row.date}</StyledTableCell>
                                            <StyledTableCell align="center">{row.description}</StyledTableCell>
                                            <StyledTableCell align="center">{row.from?.number}</StyledTableCell>
                                            <StyledTableCell align="center">{row.from?.address}</StyledTableCell>
                                            <StyledTableCell align="center">{row.hash}</StyledTableCell>
                                            <StyledTableCell align="center">{row.to?.number}</StyledTableCell>
                                            <StyledTableCell align="center">{row.to?.address}</StyledTableCell>
                                            <StyledTableCell>{row.value.eth}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <MyPagination
                            currentPerPage={currentPerPage}
                            totalMovement={movementsToken.length}
                            paginate={paginate}
                        />
                        <div className={style.btns_add_exit}>
                            <Button onClick={() => setOpenMovementsToken(false)} sx={{ mt: 5 }} variant="outlined">Ok</Button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

