import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import styles from './ModalRejected.module.css';
import iconCross from '../../../assets/img/cross.svg';
import iconWaiting from '../../../assets/img/waiting.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: 320,
  bgcolor: 'background.paper',
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: '#ffffff',
  background: '#0c0e1c',
  p: '16px',
};

const ModalRejected = (props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.header}>
            <h4>Wait a few minutes</h4>
            <div onClick={props.handleClose}>
              <img src={iconCross} alt="" />
            </div>
          </div>
          <div className={styles.admissible}>
            <img src={iconWaiting} alt="" />
          </div>
          <div className={styles.redBlock} onClick={props.handleClose}>
            <div className={styles.redText}>
              <p>Close</p>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
ModalRejected.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
};
export default ModalRejected;
