import { createContext, useState } from "react";

export const AuthContent = createContext(null);
export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [password, setPassword] = useState(null);

    const signin = (newUser, cb) => {
        setUser(newUser);
        setPassword(newUser);
        cb();
    }

    const signout = (cb) => {
        setUser(null);
        setPassword(null);
        cb();
    }
    const value = {user, password, signin, signout};
    // const value = {user, password,  signout};

    return <AuthContent.Provider value={value}>
        {children}
    </AuthContent.Provider>
}
