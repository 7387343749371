import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hook/useAuth';
import { Buffer } from 'buffer';
import { REACT_APP_API_ENDPOINT } from '../../conf'
import { localhost } from '../../HomePage'
import base64 from 'base-64';
import { encode } from "base-64"
import axios from 'axios';
import style from './LoginPage.module.css';

const LoginPage = (props) => {

    const [open, setOpen] = useState(false);
    const [getData, setGetData] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const { signin } = useAuth();
    const fromPage = location.state?.from?.pathname || '/homepage';
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        const user = form.username.value;
        const password = form.password.value;
        const graphqlRefresh = JSON.stringify({
            query: `{refresh(policy: TRANSFERS_WITH_WALLETS)}`
        })

        props.setLogin(user)
        props.setPassword(password)

        axios.post(REACT_APP_API_ENDPOINT, JSON.stringify({ query: `{usernames}` }), {
            headers: { 'Authorization': 'Basic ' + base64.encode(user + ":" + password) }
        })
            .then(res => {
                if(res.data.data) {
                    axios.post(`${REACT_APP_API_ENDPOINT}`, graphqlRefresh,
                    { headers: { 'Authorization': 'Basic ' + base64.encode(user + ":" + password) } })
                        .then(()=> {
                            signin(user + ":" + password, () => navigate(fromPage, { replace: true }))
                        })
                        .catch(err => {
                            console.log(err)
                            signin(user + ":" + password, () => navigate(fromPage, { replace: true }))  
                        })
                } else {
                    return
                }
            })
            .catch(err => console.log(err))
    }
  
    useEffect(() => {
    }, [])
    return (
        <div className={style.style_all_content}>
            <div className={style.content}>
                <h1 className={style.title}>Authorization</h1>
                <form className={style.form_inputs} onSubmit={handleSubmit}>
                    <input name="username" placeholder='Username' className={style.style_input} />
                    <input id="password" type="password" name="password" placeholder='Password' className={style.style_input} />
                    <button className={style.style_input_button} type="submit" onClick={() => setOpen(true)}>Send</button>
                </form>
            </div>
        </div>
    )
}
export default LoginPage;