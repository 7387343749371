import { useState, useEffect } from 'react';
import { REACT_APP_API_ENDPOINT } from '../conf'
import axios from 'axios';

export default function useReadOnly(auth) {
  const [isReadOnly, setIsReadOnly] = useState(true);
  useEffect(() => {
    axios.post(REACT_APP_API_ENDPOINT, JSON.stringify({ query: `{isCurrentUserReadonly}` }), {
        headers: { 'Authorization': 'Basic ' + auth } //base64.encode(user + ":" + password)
    })
        .then(res => {
            if(res.data.data) {
                setIsReadOnly(res.data.data.isCurrentUserReadonly);
            }
        })
        .catch(err => console.log(err))
  });

  return isReadOnly;
}