import React from "react";
import style from '../../HomePage.module.css';

const MyPagination = ({currentPerPage, totalMovement, paginate}) => {

    const pageNumber = []
    for (let i = 1; i <= Math.ceil(totalMovement/currentPerPage); i++ ) {
        pageNumber.push(i)
    }

    return(
       <div>
            <ul className={style.pagination}>
                {
                    pageNumber.map(number => (
                        <li key={number}>
                            <a href="#"  onClick={() => paginate(number)}>
                                {number}
                            </a>
                        </li>
                    ))
                }
            </ul>
       </div>
    )

}

export default MyPagination;