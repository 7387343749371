import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import styles from './ModalConnected.module.css';
import iconOk from '../../../assets/img/connected.png';
import iconCross from '../../../assets/img/cross.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: 320,
  bgcolor: 'background.paper',
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: '#ffffff',
  background: '#0c0e1c',
  p: '16px',
};

const ModalConnected = (props) => {
  const fileName = "wallet" + props.fileType;
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.header}>
            <h4>File created</h4>
            <div onClick={props.handleClose}>
              <img src={iconCross} alt="" />
            </div>
          </div>
          <div className={styles.admissible}>
            <img src={iconOk} alt="" />
          </div>
          <div className={styles.greenBlock} onClick={props.handleClose}>
            <div className={styles.greenText}>
              <p><a download={fileName} href={props.file}>download file</a></p>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
ModalConnected.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  file: PropTypes.any,
};
export default ModalConnected;
